import styled from "styled-components";

function App() {
    return <Container>🫀</Container>;
}

const Container = styled.div`
    min-height: calc(100vh);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #27d634;
    font-size: 150px;
    font-weight: bold;
    text-align: center;
    /* margin: 20px; */
    /* Heartbeat effect using keyframes */
    @keyframes heartbeat {
        0% {
            opacity: 1;
        }
        25% {
            opacity: 0.9;
        }
        50% {
            opacity: 1;
        }
        75% {
            opacity: 0.9;
        }
        100% {
            opacity: 1;
        }
    }
    animation: heartbeat 1.7s infinite; /* Adjust the duration as needed */
`;

export default App;
